export const SequenceTypes = [
	{ value: "Sequential", label: "Sequential (Rhythmical)" },
	{ value: "random", label: "Stochastic (Random)" },
	{ value: "Random", label: "Aleatory (White Noise)" },
	{ value: "Perlin", label: "Aleatory (Random Patterns)" },
	{ value: "Stable", label: "Mosaic (Emerging Patterns)" },
	{ value: "Fractal", label: "Fractal (Self-Similar Patterns" },
	{ value: "Unstable", label: "Gradient (Shifting Patterns)" },
];

export const SequenceLengths = [
	{ value: 2, label: "4 [2x2]" },
	{ value: 4, label: "16 [4x4]" },
	{ value: 8, label: "64 [8x8]" },
	{ value: 16, label: "256 [16x16]" },
	{ value: 32, label: "1024 [32x32]" },
	{ value: 64, label: "4096 [64x64]" },
];

export const SequenceIsRhythm = [
	{ value: 1, label: "Reflects Pattern Type" },
	{ value: 0, label: "Stays Constant" },
];

export const SequenceRotateEvery = [
	{ value: 0, label: "do not rotate" },
	{ value: 1, label: "every 1 cycle" },
	{ value: 2, label: "every 2 cycles" },
	{ value: 3, label: "every 3 cycles" },
	{ value: 4, label: "every 4 cycles" },
	{ value: 8, label: "every 8 cycles" },
];

export const SequenceReplace = [
	{ value: 0, label: "same variability" },
	{ value: 1, label: "different variability" },
];

export const SequenceRotateSolos = [
	{ value: false, label: "play all channels at once" },
	{ value: true, label: "use sequencer logic to choose which channel to play" },
];

export const SequenceVerbose = [
	{ value: 0, label: "do not show extra data" },
	{ value: 1, label: "show extra data" },
];

export const SequenceFractalReplace = [
	{ value: false, label: "sequential pattern cycle" },
	{ value: "Random", label: "aleatory pattern cycle" },
	{ value: "Stable", label: "mosaic pattern cycle" },
	{ value: "Fractal", label: "fractal pattern cycle" },
	{ value: "Unstable", label: "gradient pattern cycle" },
];
