import { h, Component } from "preact";

const GenerateFractalSignal = require("generate-fractal-signal");
const signalGenerator = GenerateFractalSignal;

import MidiRhythm from "../models/Midi";

const DEFAULT_SQUARE_DIMENSION = 16; // 64, 32, 16, 8, 4, 2, 1

const DEFAULT_GRID_LENGTH = Math.pow(DEFAULT_SQUARE_DIMENSION, 2); // 4096, 1024, 256, 64, 16, 4, 1

const ROTATION_STAGES = [
	"Random", // Aleatory, Cyclical, Stochastic (Sequential, Perlin, random, Random)
	"Stable", // Mosaic, Rhythmical
	"Fractal", // Pattern
	"Unstable", // Shifting, Gradient
];

const rotationConfig = {
	signalLength: 128,
	minWindow: 4,
	scaleGrow: 0.5,
	signalRange: [1, 4],
	signalType: "Random",
};

const generatedDataRandom = signalGenerator.generateSignal(rotationConfig);
rotationConfig.signalType = "Stable";
const generatedDataStable = signalGenerator.generateSignal(rotationConfig);
rotationConfig.signalType = "Fractal";
const generatedDataFractal = signalGenerator.generateSignal(rotationConfig);
rotationConfig.signalType = "Unstable";
const generatedDataUnstable = signalGenerator.generateSignal(rotationConfig);
rotationConfig.signalType = "Perlin";
const generatedDataPerlin = signalGenerator.generateSignal(rotationConfig);

function translateNumbersToStates(timeSeries) {
	return timeSeries.map((value) => {
		switch (value) {
			case 1:
				return "Random";
			case 2:
				return "Stable";
			case 3:
				return "Fractal";
			case 4:
				return "Unstable";
		}
	});
}

const FRACTAL_ROTATION_STAGES = {
	Sequential: ROTATION_STAGES,
	Random: translateNumbersToStates(generatedDataRandom.timeSeries),
	random: translateNumbersToStates(generatedDataRandom.timeSeries),
	Perlin: translateNumbersToStates(generatedDataPerlin.timeSeries),
	Stable: translateNumbersToStates(generatedDataStable.timeSeries),
	Fractal: translateNumbersToStates(generatedDataFractal.timeSeries),
	Unstable: translateNumbersToStates(generatedDataUnstable.timeSeries),
};

class Grid extends Component {
	constructor(props) {
		super(props);
		this.updateCurrentMessage = this.updateCurrentMessage.bind(this);
		this.state = {
			currentPosition: this.props.currentPosition,
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		// Update only if certain props or states have changed
		const modeChanged = this.props.mode !== nextProps.mode;
		const pauseChanged = this.props.pause !== nextProps.pause;

		const positionChanged =
			this.state.currentPosition !== nextState.currentPosition;

		if (pauseChanged) {
			this.setState((prevState) => ({
				currentPosition: 0,
			}));
		}

		return modeChanged || positionChanged;
	}

	generateGridData(mode, range, lengthOfSignal) {
		let data = [];

		const signalType = mode == "random" ? "Random" : mode;

		const beVerbose =
			this && this.props && this.props.verbose ? this.props.verbose : false;

		const signalConfig = {
			signalLength: lengthOfSignal < 64 ? 64 : lengthOfSignal,
			minWindow: 4,
			scaleGrow: 0.5,
			signalRange: range,
			signalType: signalType,
		};

		const gridLength = lengthOfSignal;

		switch (mode) {
			case "random":
				for (let i = 0; i < gridLength; i++) {
					data.push(Math.floor(Math.random() * range[1]) + 1);
				}
				break;
			case "Sequential":
				for (let i = 0; i < gridLength; i++) {
					const oneValue = Math.floor((((i % 4) + 1) * range[1]) / 4);
					const anotherValue = Math.floor((((i % 2) + 1) * range[1]) / 4);
					data.push(Math.random() > 0.5 ? oneValue : anotherValue);
				}
				break;
			default:
				const generatedData = signalGenerator.generateSignal(signalConfig);

				if (beVerbose) {
					// console.log(`${mode} alpha: ${generatedData.alpha.toFixed(2)}`);
					this.setState((prevState) => ({
						currentMessage: `${mode} alpha: ${generatedData.alpha.toFixed(2)}`,
					}));
				}

				data = generatedData.timeSeries
					? generatedData.timeSeries.slice(0, gridLength)
					: [];

				break;
		}

		return data;
	}

	updatePropsReorganizedData(data) {
		const reorganizedData = this.reorganizeArray(data);
		this.props.reorganizedData = reorganizedData;
		if (this.props.updateReorganizedData) {
			this.props.updateReorganizedData(reorganizedData);
		}
	}

	renderGrid(mode) {
		const { loopOn, currentPosition } = this.state;

		const currentCycle = parseInt(this.props.currentCycle);

		const firstRender = currentPosition === 0 && !loopOn;

		const shouldLoop =
			this.props.reorganizedData &&
			currentPosition == this.props.reorganizedData.length - 1;

		const lengthOfSignal = this.props.rows
			? parseInt(Math.pow(this.props.rows, 2))
			: DEFAULT_GRID_LENGTH;

		const rotateEvery = this.props.rotate ? parseInt(this.props.rotate) : 0;

		const shouldReplaceOnRotation = this.props.replace ? true : false;

		const currentView = this.props.currentView;

		if (firstRender) {
			const data = this.generateGridData(mode, [1, 4], lengthOfSignal);
			this.updatePropsReorganizedData(data);

			if (currentView != "all") {
				this.playMidi(
					data,
					mode,
					lengthOfSignal,
					this.props.channelsToNotes,
					currentCycle
				);
			}
		}

		if (shouldLoop && !firstRender) {
			this.setState((prevState) => ({
				loopOn: true,
			}));

			if (rotateEvery && currentCycle != 0 && currentCycle % rotateEvery == 0) {
				let newMode = mode;
				if (shouldReplaceOnRotation) {
					const oldMode = this.props.tempMode ? this.props.tempMode : mode;
					console.log("fractalReplace", this.props.fractalReplace);
					if (this.props.fractalReplace) {
						newMode = findNextAfterCycle(
							FRACTAL_ROTATION_STAGES[this.props.fractalReplace],
							currentCycle
						);
					} else {
						newMode = findNextAfterString(ROTATION_STAGES, oldMode);
					}

					console.log("switching to mode", newMode);
					if (this.props.updateTempMode) {
						this.props.updateTempMode(newMode);
					}
				}
				const newData = this.generateGridData(newMode, [1, 4], lengthOfSignal);
				this.updatePropsReorganizedData(newData);
				this.playMidi(
					newData,
					newMode,
					lengthOfSignal,
					this.props.channelsToNotes,
					currentCycle
				);
			} else {
				const dataToLoop = this.reorganizeArray(this.props.reorganizedData);
				const firstSound = dataToLoop.shift();
				dataToLoop.push(firstSound);
				// const secondSound = dataToLoop.shift();
				// dataToLoop.push(secondSound);
				this.playMidi(
					dataToLoop,
					mode,
					lengthOfSignal,
					this.props.channelsToNotes,
					currentCycle
				);
			}
		}

		let dataToShow = this.props.reorganizedData;

		if (!firstRender) {
			if (currentPosition > 0) {
				dataToShow = this.reorganizeArray(this.props.reorganizedData);
				const firstElement = dataToShow.shift();
				dataToShow.push(firstElement);
				dataToShow = this.reorganizeArray(dataToShow);

				this.props.updateCurrentPosition(currentPosition);
				this.props.updateReorganizedData(dataToShow);
			}
		}

		if (currentPosition == lengthOfSignal - 1) {
			if (this.props.updateCurrentCycle) {
				this.props.updateCurrentCycle(currentCycle + 1);
			}
		}

		const returnedData = dataToShow.map((value, index) => {
			// if (index == 0 && this.props.pause) console.log("square value", value);
			const isActive = index == 0 && this.props.pause;
			return <div class={this.getColor(value, isActive)}></div>;
		});

		return returnedData;
	}

	async playMidi(data, mode, lengthOfSignal, channelsToNotes, currentCycle) {
		let timeOutIds = null;

		// if (this.props.timeOutIds && !this.props.pause) {
		// 	this.props.timeOutIds.forEach((id) => {
		// 		clearTimeout(id);
		// 	});
		// }

		if (this.props.pause) {
			const midiPlayer = this.props.midiPlayer;

			const onNotePlayed = (index) => {
				this.setState((prevState) => ({
					currentPosition: index,
				}));
			};

			const msRange = [
				this.props.beatDistance - this.props.beatDistance / 2,
				this.props.beatDistance + this.props.beatDistance / 2,
			];

			const noteLength = Math.floor(
				(this.props.beatDistance - this.props.beatDistance / 2) / 2
			);

			const rythmData = this.props.formToRhythm
				? this.generateGridData(mode, msRange, lengthOfSignal) // 50, 450 //  50, 950 // 200, 1300
				: null;

			timeOutIds = await midiPlayer.play(
				this.props.WebMidi,
				data,
				this.props.pause,
				"loop",
				rythmData,
				onNotePlayed,
				this.generateGridData,
				mode,
				lengthOfSignal,
				this.props.midiOutput,
				channelsToNotes,
				currentCycle,
				this.props.rotateSolos,
				noteLength,
				this.props.verbose,
				this.updateCurrentMessage
			);
		}

		if (this.props.updateTimeOutIds) this.props.updateTimeOutIds(timeOutIds);
	}

	getColor(value, isActive) {
		const setActive = isActive ? " border-4 border-solid border-white-300" : "";

		switch (value) {
			case 1:
				return "w-full h-full bg-clinical-950 aspect-square" + setActive;
			case 2:
				return "w-full h-full bg-clinical-600 aspect-square" + setActive; // 33% gradation
			case 3:
				return "w-full h-full bg-clinical-300 aspect-square" + setActive; // 66% gradation
			case 4:
				return "w-full h-full bg-clinical-50 aspect-square" + setActive; // bg-emerald
			default:
				return "w-full h-full transparent aspect-square";
		}
	}

	updateCurrentMessage(message) {
		this.setState((prevState) => ({
			currentMessage: message,
		}));
	}

	reorganizeArray(arr) {
		const n = arr.length;
		const chunkSize = Math.sqrt(n);
		if (!Number.isInteger(chunkSize)) {
			throw new Error("Array size must be a perfect square");
		}

		let result = [];
		for (let i = 0; i < n; i += chunkSize) {
			let chunk = arr.slice(i, i + chunkSize);
			if ((i / chunkSize) % 2 !== 0) {
				chunk = chunk.reverse();
			}
			result = result.concat(chunk);
		}

		return result;
	}

	render() {
		const currentMode = this.props.mode;

		const midiPlayer = new MidiRhythm();

		this.props.midiPlayer = midiPlayer;

		const beVerbose = this.props.verbose ? true : false;

		const gridRows =
			this.props.rows && this.props.rows % 2 == 0
				? parseInt(this.props.rows)
				: DEFAULT_SQUARE_DIMENSION;

		return (
			<div
				key={this.props.key}
				onTouchStart={this.props.onTouchStart}
				onClick={this.props.onClick}
				class="container w-4/5 lg:w-2/5 flex-1 p-0 items-center mt-48 lg:mt-0 justify-center"
				style="aspect-ratio: 1 / 1;"
			>
				{beVerbose && (
					<p class="font-sans text-lg mb-4 text-gray-600">
						{this.state.currentMessage ? this.state.currentMessage : "\u00A0"}
					</p>
				)}
				{gridRows == 1 && (
					<div class="grid grid-cols-1" style="aspect-ratio: 1 / 1;">
						{this.renderGrid(currentMode)}
					</div>
				)}
				{gridRows == 2 && (
					<div class="grid grid-cols-2" style="aspect-ratio: 1 / 1;">
						{this.renderGrid(currentMode)}
					</div>
				)}
				{gridRows == 4 && (
					<div class="grid grid-cols-4" style="aspect-ratio: 1 / 1;">
						{this.renderGrid(currentMode)}
					</div>
				)}
				{gridRows == 8 && (
					<div class="grid grid-cols-8" style="aspect-ratio: 1 / 1;">
						{this.renderGrid(currentMode)}
					</div>
				)}
				{gridRows == 16 && (
					<div class="grid grid-cols-16" style="aspect-ratio: 1 / 1;">
						{this.renderGrid(currentMode)}
					</div>
				)}
				{gridRows == 32 && (
					<div class="grid grid-cols-32" style="aspect-ratio: 1 / 1;">
						{this.renderGrid(currentMode)}
					</div>
				)}
				{gridRows == 64 && (
					<div class="grid grid-cols-64" style="aspect-ratio: 1 / 1;">
						{this.renderGrid(currentMode)}
					</div>
				)}
			</div>
		);
	}
}

function findNextAfterString(arr, searchString) {
	// Check if the array is not empty
	if (arr.length === 0) {
		return null; // Or some other value indicating the array is empty
	}

	// Iterate through the array to find the string
	for (let i = 0; i < arr.length - 1; i++) {
		// Check if the current element is the search string
		if (arr[i] === searchString) {
			// Return the next element in the array
			return arr[i + 1];
		}
	}

	// If the string wasn't found or is the last element, return the first element
	return arr[0];
}

function findNextAfterCycle(array, cycle) {
	const index = (cycle + 1) % array.length;
	return array[index];
}

export default Grid;
