import { h } from "preact";
import { useState, useMemo } from "preact/hooks";

import { memo } from "preact/compat";

import Select from "./Select";
import TextInput from "./TextInput";
import UrlInput from "./UrlInput";
import TextInputChannel from "./TextInputChannel";
import TextInputNote from "./TextInputNote";
import TextInputBpm from "./TextInputBpm";
import Button from "./Button";

const Settings = memo(
	({
		updateDrawerOpen,
		SequenceTypes,
		leftDeck,
		leftPause,
		updateLeftDeck,
		rightDeck,
		rightPause,
		updateRightDeck,
		midiOutput,
		updateMidiOutput,
		midiDevices,
		rowsLength,
		updateRowsLength,
		SequenceLengths,
		sequenceIsRhythm,
		updateSequenceRhythm,
		SequenceIsRhythm,
		rotateEvery,
		updateRotateEvery,
		SequenceRotateEvery,
		shouldReplace,
		updateShouldReplace,
		SequenceReplace,
		fractalReplace,
		updateFractalReplace,
		SequenceFractalReplace,
		rotateSolos,
		updateRotateSolos,
		SequenceRotateSolos,
		bpm,
		updateBpm,
		channelsToNotesMap,
		updatePresetChannels,
		updatePresetNotes,
		savedSettingsUrl,
		fullSettingsUrl,
		presetSettingsUrl,
		loadPresetUrl,
		allPresetUrls,
		shouldBeVerbose,
		updateShouldBeVerbose,
		SequenceVerbose,
		addToPresets,
		presetName,
		updatePresetName,
		savedPresetName,
		channelsInChords,
	}) => {
		return (
			<div class="flex bg-black" id="settings-panel" key="settings-panel">
				<input
					type="checkbox"
					id="drawer-toggle"
					class="relative sr-only peer"
					onClick={() => updateDrawerOpen()}
				></input>
				<label
					for="drawer-toggle"
					class="absolute top-0 left-0 inline-block w-16 h-16 p-4 transition-all duration-500 bg-black peer-checked:rotate-90 peer-checked:left-96"
				>
					<div class="w-7 h-1 mb-1 bg-neutral-500 rounded-lg"></div>
					<div class="w-7 h-1 mb-1 bg-neutral-500 rounded-lg"></div>
					<div class="w-7 h-1 bg-neutral-500 rounded-lg"></div>
				</label>
				<div class="fixed top-0 left-0 z-20 w-96 h-full overflow-auto text-xs bg-black transition-all duration-500 transform -translate-x-full shadow-lg peer-checked:translate-x-0">
					<div class="px-6 py-4 bg-black text-xs font-mono">
						<h2 class="text-lg text-gray-400 font-semibold">
							Fractal Rhythm Settings
						</h2>
						<p class="text-gray-500 text-xs py-4">
							To activate, click the square, and start the sequencer (space
							button). To save or share your settings, please, copy & reload the
							URL.
						</p>

						<h3 class="pt-4 text-base text-gray-400 font-semibold ">
							Pattern Decks
						</h3>
						<Select
							selectedValue={leftDeck}
							onSelectedValueChange={updateLeftDeck}
							label="Left"
							options={SequenceTypes}
							disabled={leftPause}
						/>
						<Select
							selectedValue={rightDeck}
							onSelectedValueChange={updateRightDeck}
							label="Right"
							options={SequenceTypes}
							disabled={rightPause}
						/>
						<h3 class="pt-8 text-base text-gray-400 font-semibold">
							MIDI Devices
						</h3>
						{!midiOutput.value && (
							<p class="text-xs pt-1 text-gray-400">
								Please, activate or connect a MIDI device and configure it for
								receiving MIDI messages.
							</p>
						)}
						<Select
							selectedValue={midiOutput.value}
							onSelectedValueChange={updateMidiOutput}
							label="MIDI Output"
							options={midiDevices}
						/>
						<h3 class="pt-8 text-base text-gray-400 font-semibold">
							Sequencer
						</h3>
						<Select
							selectedValue={rowsLength}
							onSelectedValueChange={updateRowsLength}
							label="Steps"
							options={SequenceLengths}
						/>
						<Select
							selectedValue={sequenceIsRhythm}
							onSelectedValueChange={updateSequenceRhythm}
							label="Rhythm"
							options={SequenceIsRhythm}
						/>
						<Select
							selectedValue={rotateEvery}
							onSelectedValueChange={updateRotateEvery}
							label="Change Pattern"
							options={SequenceRotateEvery}
						/>
						<Select
							selectedValue={shouldReplace}
							onSelectedValueChange={updateShouldReplace}
							label="Change Type"
							options={SequenceReplace}
						/>
						<Select
							selectedValue={fractalReplace}
							onSelectedValueChange={updateFractalReplace}
							label="Vary Change"
							options={SequenceFractalReplace}
						/>
						<Select
							selectedValue={rotateSolos}
							onSelectedValueChange={updateRotateSolos}
							label="Vary Solos"
							options={SequenceRotateSolos}
						/>

						<TextInputBpm
							defaultValue={bpm}
							onValueChange={updateBpm}
							label="BPM"
							// pause={pause}
							// updatePause={updatePause}
						/>

						<h3 class="pt-8 text-base text-gray-400 font-semibold">
							Channels to Chords Variations
						</h3>

						{channelsToNotesMap.map((pattern, patternIndex) => {
							return pattern.map((channel, channelIndex) => (
								<div class="flex flex-col">
									<div class="flex pt-4 ">
										<TextInputChannel
											defaultValue={channel.channel}
											onValueChange={(newValue) =>
												updatePresetChannels(
													patternIndex,
													channelIndex,
													newValue
												)
											}
											// pause={pause}
											// updatePause={updatePause}
										/>
										<p class="text-sm text-gray-400 font-semibold mr-4 pt-4">
											to
										</p>
										{channel.notes.map((note, noteIndex) => (
											<TextInputNote
												defaultValue={note}
												onValueChange={(newValue) =>
													updatePresetNotes(
														patternIndex,
														channelIndex,
														noteIndex,
														newValue
													)
												}
												channel={channel.channel}
												note={note}
												// pause={pause}
												// updatePause={updatePause}
											/>
										))}
									</div>
									{channelIndex != 0 &&
										channelIndex % (channelsInChords - 1) == 0 && (
											<hr class="mt-4 border-gray-300" />
										)}
								</div>
							));
						})}

						<h3 class="pt-8 text-base text-gray-400 font-semibold">
							Presets URL
						</h3>
						<p class="pt-2 text-xs text-gray-400">
							save or share this URL to open the current setup
						</p>
						<UrlInput defaultValue={savedSettingsUrl} />
						<TextInput
							defaultValue={presetName}
							onValueChange={updatePresetName}
						/>
						<div class="flex">
							<Button
								onClick={() => addToPresets(fullSettingsUrl, savedPresetName)}
								label="Save to Presets"
							></Button>
							<Button
								onClick={() => loadLink(fullSettingsUrl)}
								label="Open in New Tab"
							></Button>
						</div>
						<h3 class="pt-8 text-base text-gray-400 font-semibold">
							Load Presets
						</h3>
						<Select
							selectedValue={presetSettingsUrl}
							onSelectedValueChange={loadPresetUrl}
							options={allPresetUrls}
						/>
						<Button
							onClick={() => removeLocalStorage()}
							label="Clear Your Presets"
						></Button>
						<h3 class="pt-8 text-base text-gray-400 font-semibold">
							Advanced Settings
						</h3>

						<Select
							selectedValue={shouldBeVerbose}
							onSelectedValueChange={updateShouldBeVerbose}
							label="Debug Mode"
							options={SequenceVerbose}
						/>
						<p class="pt-8 text-xs text-gray-400">&nbsp;</p>
					</div>
				</div>
			</div>
		);
	}
);

function loadLink(url) {
	window.open(url, "_blank");
}

function removeLocalStorage() {
	confirm(
		"Are you sure you want to remove all your presets from the browser's cache and reload this page?",
		localStorage.removeItem("presetUrls")
	);
	window.location.href = "/";
}

export default Settings;
