import { h } from "preact";
import { useState } from "preact/hooks";

const channelRange = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

const TextInputChannel = ({ defaultValue, onValueChange, channel, note }) => {
	const [value, setValue] = useState(defaultValue);

	const channelId = `ch${channel}-${note}`;

	const handleChange = (event) => {
		const newValue = event.target.value;

		const intValue = parseInt(newValue, 10);

		if (!isNaN(intValue)) {
			setValue(intValue);
		}
	};

	const handleFocus = () => {};
	const handleBlur = () => {
		if (channelRange.includes(value) == false) {
			alert("Channel can be a number between 0 and 16");
			return;
		}
		if (!isNaN(value)) {
			onValueChange(value);
		}
	};

	return (
		<input
			type="text"
			id={channelId}
			value={value}
			onChange={handleChange}
			onFocus={handleFocus}
			onBlur={handleBlur}
			class="bg-gray-950 pl-2 border border-gray-300 text-gray-500 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 w-8 mr-4 p-2.5"
		/>
	);
};

export default TextInputChannel;
