import { h } from "preact";
import { useEffect, useState } from "preact/hooks";

const UrlInput = ({ defaultValue, label }) => {
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		setValue(decodeURIComponent(defaultValue));
	}, [defaultValue]);

	return (
		<div class="flex pt-4 items-center">
			{label && (
				<label
					htmlFor="text-input"
					class="block mt-2 mr-2 text-xs w-16 font-medium text-gray-500"
				>
					{label}
				</label>
			)}

			<input
				type="text"
				id="text-input"
				value={value}
				readOnly
				class="bg-gray-950 pl-2 border border-gray-300 text-gray-500 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
			/>
		</div>
	);
};

export default UrlInput;
