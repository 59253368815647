export const defaultChords = [
	[
		{
			channel: 1,
			notes: ["C2", "D3", "F3", "G2"],
		},
	],
	[
		{
			channel: 1,
			notes: ["C2", "D3", "F3", "G2"],
		},
	],
];

export const diverseChords = [
	[
		{
			channel: 5,
			notes: ["C3", "D3", "F3", "G3"],
		},
		{
			channel: 6,
			notes: ["F2", "G2", "Bb2", "C2"],
		},
		{
			channel: 8,
			notes: ["Bb3", "G2", "Bb3", "C3"],
		},
		{
			channel: 1,
			notes: ["B3", "A3", "F3", "G3"],
		},
	],
	[
		{
			channel: 5,
			notes: ["B3", "E3", "G3", "F3"],
		},
		{
			channel: 6,
			notes: ["E2", "A2", "D2", "B2"],
		},
		{
			channel: 8,
			notes: ["G3", "D2", "B3", "D3"],
		},
		{
			channel: 1,
			notes: ["B3", "A3", "F3", "G3"],
		},
	],
];
