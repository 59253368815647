import { h } from "preact";
import { useState } from "preact/hooks";

const TextInputNote = ({ defaultValue, onValueChange, channel, note }) => {
	const [value, setValue] = useState(defaultValue);

	const channelId = `ch${channel}-${note}`;

	const handleChange = (event) => {
		const newValue = event.target.value;

		setValue(newValue);
	};

	const handleFocus = () => {};

	const handleBlur = () => {
		if (isMusicalNote(value) == false) {
			alert("You did not provide a correct musical note. Please, try again.");
			return;
		}
		if (value) {
			onValueChange(value);
		}
	};

	return (
		<input
			type="text"
			id={channelId}
			value={value}
			onChange={handleChange}
			onFocus={handleFocus}
			onBlur={handleBlur}
			class="bg-gray-950 pl-2 border border-gray-300 text-gray-500 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 w-10 mr-4 p-2.5"
		/>
	);
};

function isMusicalNote(note) {
	const regex = /^[A-Ga-g](#|b)?[0-8]?$/;
	return regex.test(note);
}

export default TextInputNote;
