import { h } from "preact";
import { useState } from "preact/hooks";

const TextInputBpm = ({
	defaultValue,
	onValueChange,
	pause,
	updatePause,
	label,
	options,
	readOnly,
}) => {
	const [value, setValue] = useState(defaultValue);

	const handleChange = (event) => {
		const newValue = event.target.value;

		const intValue = parseInt(newValue, 10);
		if (!isNaN(intValue)) {
			setValue(intValue);
		}
	};

	const handleFocus = () => {
		// updatePause();
	};

	const handleBlur = () => {
		if (isNaN(value) || !value) {
			alert("BPM must be a number");
			return;
		}
		if (!isNaN(value) && value > 0) {
			onValueChange(value);
		}
	};

	return (
		<div class="flex pt-4 items-center">
			{label && (
				<label
					htmlFor="text-input"
					class="block mt-2 mr-2 text-xs w-16 font-medium text-gray-500"
				>
					{label}
				</label>
			)}

			<input
				type="text"
				id="text-input"
				value={value}
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
				readOnly={readOnly}
				class="bg-gray-950 pl-2 border border-gray-300 text-gray-500 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
			/>
		</div>
	);
};

export default TextInputBpm;
