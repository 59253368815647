import { h, Component } from "preact";

const midiSettings = {
	defaultLength: 250,
	defaultRhythm: 120,
};

class MidiRhythm extends Component {
	convertSignalToNotes(signalArray, signalToNotesMap) {
		const notesArray = signalArray.map((signal) => {
			return signalToNotesMap.map((channel) => {
				return channel.notes[signal - 1];
			});
		});
		return notesArray;
	}

	playArrayToMidi(
		WebMidi,
		notesArray,
		loop,
		timeSeries,
		onNotePlayed,
		generateGridData,
		mode,
		lengthOfSignal,
		midiOutput,
		channelsToNotes,
		rotateSolos,
		noteLength,
		beVerbose,
		setCurrentMessage
	) {
		const midiExists = WebMidi && midiOutput && midiOutput.label ? true : false;

		const myOutput =
			midiExists && midiOutput ? WebMidi.getOutputByName(midiOutput.label) : {};

		const myChannels = channelsToNotes.map((state) => state.channel); // const myChannel = myOutput.channels[midiSettings.defaultChannel];

		let startingPoint = 0;
		const delays = new Array(notesArray.length).fill().map((_, i) => {
			if (timeSeries && timeSeries[i]) {
				startingPoint += timeSeries[i];
			} else {
				startingPoint += (60 / midiSettings.defaultRhythm) * 1000;
			}
			return startingPoint;
		});

		const possibleCombos = getAllCombinations(myChannels);

		const soloGrid =
			possibleCombos && possibleCombos.length > 1
				? generateGridData(mode, [0, possibleCombos.length - 1], lengthOfSignal)
				: new Array(notesArray.length).fill(0);

		console.log("rotateSolos", rotateSolos);
		console.log("soloGrid", soloGrid);

		const timeOutIds = notesArray.map((note, index) => {
			const timeoutId = setTimeout(() => {
				const comboOfChannels = soloGrid[index];

				if (midiExists) {
					myChannels.forEach((channel, _index) => {
						if (
							!rotateSolos ||
							possibleCombos[comboOfChannels].includes(channel)
						) {
							const ind = _index; // Math.random() > 0.5 ? 0 : index;
							myOutput.playNote(note[ind], [channel], {
								duration: noteLength ? noteLength : midiSettings.defaultLength,
							});

							if (beVerbose && setCurrentMessage) {
								setCurrentMessage(
									`${note[ind]} @ ch ${channel} | ${timeSeries[index]}ms`
								);
							}
						}
					});
				}
				onNotePlayed(index);

				// myChannel.playNote(note, {
				// 	duration: midiSettings.defaultLength,
				// });
			}, delays[index]); // index * midiSettings.defaultLength
			return timeoutId;
		});

		return timeOutIds;
	}

	play(
		WebMidi,
		signalArray,
		pause,
		loop,
		timeSeries,
		onNotePlayed,
		generateGridData,
		mode,
		lengthOfSignal,
		midiOutput,
		channelsToNotes,
		currentCycle,
		rotateSolos,
		noteLength,
		beVerbose,
		setCurrentMessage
	) {
		const shouldPause = pause ? false : true;

		let myOutput = false;

		if (midiOutput && midiOutput.value) {
			myOutput = WebMidi.getOutputById(midiOutput.value);
		}

		if (shouldPause) {
			if (myOutput) myOutput.sendAllSoundOff();

			// if (this.props.timeOutIds) {
			// 	this.props.timeOutIds.forEach((id) => {
			// 		clearTimeout(id);
			// 	});
			// }

			return false;
		}

		const notesArray = this.convertSignalToNotes(
			signalArray,
			channelsToNotes[currentCycle % 2]
		);

		const timeOutIds = this.playArrayToMidi(
			WebMidi,
			notesArray,
			loop,
			timeSeries,
			onNotePlayed,
			generateGridData,
			mode,
			lengthOfSignal,
			midiOutput,
			channelsToNotes[currentCycle % 2],
			rotateSolos,
			noteLength,
			beVerbose,
			setCurrentMessage
		);

		return timeOutIds;
	}
}

function getAllCombinations(arr) {
	// Function to generate combinations
	function generateCombinations(subset, remainingElements, results) {
		if (remainingElements.length === 0 && subset.length > 0) {
			// If no more elements to add and subset is not empty, add the current subset
			results.push(subset);
			return;
		}

		// Stop if there are no more elements to add
		if (remainingElements.length === 0) {
			return;
		}

		// Include the next element
		generateCombinations(
			subset.concat([remainingElements[0]]),
			remainingElements.slice(1),
			results
		);

		// Exclude the next element
		generateCombinations(subset, remainingElements.slice(1), results);
	}

	const results = [];
	generateCombinations([], arr, results);
	return results;
}

export default MidiRhythm;
