import { h } from "preact";
import { useState } from "preact/hooks";

const Helper = ({}) => {
	return (
		<div class="flex bg-black">
			<input
				type="checkbox"
				id="swiper-toggle"
				class="relative sr-only peer"
				data-drawer-toggle="helper-swipe"
			></input>
			<label
				for="swiper-toggle"
				class="absolute bottom-0 right-0 inline-block w-16 h-16 p-4 transition-all duration-500 bg-black peer-checked:rotate-90 peer-checked:right-96"
			>
				<div class="w-1 h-5 mb-1 bg-neutral-500 rounded-lg"></div>
				<div class="w-6 h-1 bg-neutral-500 rounded-lg"></div>
			</label>
			<div
				id="helper-swipe"
				class="fixed bottom-0 right-0 z-20 w-96 h-full overflow-auto text-xs bg-black transition-all duration-500 transform -translate-y-full shadow-lg peer-checked:translate-y-0"
			>
				<div class="px-6 py-4 bg-black text-xs font-mono">
					<h2 class="text-lg text-gray-400 font-semibold">
						Fractal Rhythm Machine
					</h2>
					<p class="text-gray-500 text-sm py-4">
						Fractal Rhythm is a visual, sonic, and data sequencer. It produces 4
						types of variability, which is then sent to the user via MIDI (to
						activate other devices, change CV, or play MIDI notes), as a
						visualization (to be projected), and as a data stream. You can then
						use this to introduce fractal dynamics into your projects.
					</p>

					<h3 class="pt-4 text-base text-gray-400 font-semibold ">
						Quick Start
					</h3>

					<p class="text-gray-500 text-sm py-4">
						0. Open the link on your desktop computer<br></br>
						1. Click on the pattern deck on the left or on the right<br></br>
						2. Press "Space" to begin playing the pattern<br></br>
						3. Press "Space" again to stop playing the pattern and refresh it
						<br></br>
						4. Click on the pattern to zoom out and choose another pattern deck
						<br></br>
						5. Once the pattern is open, press "Space" to start playing the new
						pattern<br></br>
						6. Stop playing. Switch. Reiterate.
					</p>

					<h3 class="pt-4 text-base text-gray-400 font-semibold ">
						MIDI Setup
					</h3>

					<p class="text-gray-500 text-sm py-4">
						0. Turn your MIDI device on before opening the page. Reload the app
						if necessary to register the device. <br></br>1. Internal Mac OS
						Midi On, then open GarageBand and open a musical instrument to start
						playing. <br></br>2. Connect an external MIDI device (e.g. TE
						OPZ-1), modify notes in the Settings, and start playing.
					</p>

					<h3 class="pt-4 text-base text-gray-400 font-semibold ">
						Settings Suggestions
					</h3>

					<p class="text-gray-500 text-sm py-4">
						If you have a problem, see whether your MIDI device is displayed in
						the Settings. We recommend to start with a default pattern and then
						use the self-rotating rhytmical / pattern settings so you get more
						variability. If you like a setting you can save it as a preset.
					</p>

					<h3 class="pt-4 text-base text-gray-400 font-semibold ">
						Author & Contact
					</h3>

					<p class="text-gray-500 text-sm py-4">
						Dmitry Paranyushkin | Nodus Labs<br></br>
						<a href="https://noduslabs.com">noduslabs.com</a> |{" "}
						<a href="support.noduslabs.com">support.noduslabs.com</a>
						<br></br>
						info@noduslabs.com |{" "}
						<a href="https://twitter.com/noduslabs">@noduslabs</a>
					</p>
					<p class="pt-8 text-xs text-gray-400">&nbsp;</p>
				</div>
			</div>
		</div>
	);
};

export default Helper;
