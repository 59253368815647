import { h } from "preact";

const Select = ({
	selectedValue,
	onSelectedValueChange,
	label,
	options,
	disabled,
}) => {
	// Function to update state based on selection
	const handleChange = (event) => {
		// Call the function passed from the parent component
		onSelectedValueChange(event.target.value);
	};

	return (
		<div class="pt-4 flex text-xs">
			{label && (
				<label
					for="simple-select"
					class="block mt-2 mr-2 text-xs w-16 font-medium text-gray-500"
				>
					{label}
				</label>
			)}

			<select
				id="simple-select"
				value={selectedValue}
				onChange={handleChange}
				disabled={disabled}
				class="bg-gray-950 pl-2 text-xs border border-gray-300 text-gray-500 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
			>
				{options.map((option) => (
					<option value={option.value} key={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	);
};

export default Select;
