import { h } from "preact";
import { useState } from "preact/hooks";

const TextInput = ({
	defaultValue,
	onValueChange,
	label,
	presetName,
	readOnly,
}) => {
	const [value, setValue] = useState(defaultValue);

	const handleChange = (event) => {
		const newValue = event.target.value;

		const intValue = newValue;

		setValue(intValue);

		if (intValue && intValue.length > 0) {
			onValueChange(intValue);
		}
	};

	const handleFocus = () => {};

	const handleBlur = () => {};

	return (
		<div class="flex pt-4 items-center">
			{label && (
				<label
					htmlFor="text-input"
					class="block mt-2 mr-2 text-xs w-16 font-medium text-gray-500"
				>
					{label}
				</label>
			)}

			<input
				type="text"
				id="text-input"
				value={value}
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
				readOnly={readOnly}
				class="bg-gray-950 pl-2 border border-gray-300 text-gray-500 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
			/>
		</div>
	);
};

export default TextInput;
