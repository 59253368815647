import { h } from "preact";

const Button = ({ onClick, label, customClasses }) => {
	const classesToUse = customClasses
		? customClasses
		: "bg-clinical-600 hover:bg-clinical-300 text-black mr-2 font-bold py-2 px-4 rounded";
	return (
		<div class="pt-4 flex text-xs">
			<button onClick={onClick} class={classesToUse}>
				{label}
			</button>
		</div>
	);
};

export default Button;
