import { h, render } from "preact";
import { useState, useEffect, useRef } from "preact/hooks";

import Grid from "./components/Grid";

import "./tailwind.css";

import { WebMidi } from "webmidi";

import Helper from "./components/Helper";
import Settings from "./components/Settings";
import Button from "./components/Button";

import { defaultChords } from "./options/Chords";

import {
	SequenceTypes,
	SequenceLengths,
	SequenceIsRhythm,
	SequenceRotateEvery,
	SequenceReplace,
	SequenceVerbose,
	SequenceRotateSolos,
	SequenceFractalReplace,
} from "./options/Sequence";

const queryParams = new URLSearchParams(window.location.search);

const GenerateFractalSignal = require("generate-fractal-signal");
const signalGenerator = GenerateFractalSignal;

const defaultBpm = queryParams.get("bpm")
	? parseInt(queryParams.get("bpm"), 10)
	: 200;
const rows = queryParams.get("rows")
	? parseInt(queryParams.get("rows"), 10)
	: 16;
const verbose = queryParams.get("verbose")
	? parseInt(queryParams.get("verbose"), 10)
	: 0;
const left = queryParams.get("left") ? queryParams.get("left") : "Random";
const right = queryParams.get("right") ? queryParams.get("right") : "Fractal";
const rotate = queryParams.get("rotate")
	? parseInt(queryParams.get("rotate"), 10)
	: 0;
const replace = queryParams.get("replace")
	? parseInt(queryParams.get("replace"), 10)
	: 0;
const switcher = queryParams.get("switcher")
	? queryParams.get("switcher")
	: false;
const rhythm = queryParams.get("rhythm") ? queryParams.get("rhythm") : 1;

const presetMidi = queryParams.get("midi") ? queryParams.get("midi") : false;

const presetChannels = queryParams.get("channels")
	? queryParams.get("channels").split(" ")
	: false;

const presetNotes = queryParams.get("notes")
	? queryParams.get("notes").split(" ")
	: false;

const channelsInChords = presetChannels
	? Math.floor(presetChannels.length / defaultChords.length)
	: Math.floor(defaultChords[0].length);

const playAllChannelsAtOnce = queryParams.get("chorus") ? true : false;

const channelsToNotes =
	!presetChannels && !presetNotes
		? defaultChords
		: defaultChords.map((pattern, patternIndex) => {
				return presetChannels
					.map((channel, channelIndex) => {
						if (
							patternIndex * channelsInChords <= channelIndex &&
							channelIndex < channelsInChords * (patternIndex + 1)
						) {
							return {
								channel: channel,
								notes: presetNotes.slice(
									4 * channelIndex,
									4 * channelIndex + 4
								),
							};
						}
					})
					.filter((channel) => channel);
		  });

console.log("channelsToNotes", channelsToNotes);

const presetSettingsUrl = new URL(window.location.href);

const bpmToMs = (bpm) => {
	return Math.floor((60 / bpm) * 1000);
};

const returnTimeSeries = (signalConfig) => {
	return signalGenerator.generateSignal(signalConfig);
};

function enableWebMidi(setWebMidi) {
	console.log("Enabling webmidi");
	const isSafari = detectSafari();
	console.log("isSafari", isSafari);
	if (isSafari) {
		alert(
			"WebMidi is not supported on Safari. Please use Chrome or Firefox instead. You can still use it for fractal visualizations."
		);
		return;
	}
	WebMidi.enable()
		.then(() => {
			console.log("WebMidi enabled");
			console.log(WebMidi.inputs);
			console.log(WebMidi.outputs);
			const midiInputs = WebMidi.inputs;
			const midiInputsExist = midiInputs && midiInputs.length > 0;

			if (!midiInputsExist) {
				alert(
					"No MIDI inputs detected. Please connect your software or hardware MIDI device (e.g. Bespoke, GarageBand, Analog Rhytm, OPZ) or activate it to receive incoming signals."
				);
				return;
			}
			setWebMidi(WebMidi);
		})
		.catch((err) => alert(err));
}

function setMidiInputListener(myInput, shouldBeVerbose) {
	myInput.addListener(
		"noteon",
		(e) => {
			if (shouldBeVerbose) {
				console.log(
					`received ${e.note._name}${e.note.octave} on ch ${e.message.channel}`
				);
			}
		},
		{
			channels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
		}
	);
}

const presetUrls = [
	{
		value: "/",
		label: "Default Mode: Fractal Pattern, Fast, 256 Steps, Stable",
	},
	{
		value: "/?rows=4",
		label: "Easy Mode: Fractal Pattern, Fast, 16 Steps, Stable",
	},
	{
		value:
			"/?channels=1+1&notes=B1+A1+G1+E1+C2+A1+B1+D2&bpm=400&rows=8&rotate=1",
		label: "Garage Band w So Cal Drum, Fractal Rhythm (1 channel, 2 patterns)",
	},
	{
		value:
			"/?channels=1+1&notes=B1+A1+G1+E1+C2+A1+B1+D2&bpm=400&rows=8&rotate=1&replace=1&switcher=Fractal",
		label: "Garage Band w So Cal Drum, Rhythm Changes (4 channels, 2 patterns)",
	},
	{
		value:
			"/?rows=8&bpm=400&rotate=2&channels=5+6+8+1+5+6+8+1&notes=C3+D3+F3+G3+F2+G2+Bb2+C2+Bb3+G2+Bb3+C3+B3+A3+F3+G3+B3+E3+G3+F3+E2+A2+D2+B2+G3+D2+B3+D3+B3+A3+F3+G3",
		label:
			"Elektron Analog Rytm: Fast Drumming & Rhythm, Change Pattern Every 2 Cycles",
	},
	{
		value:
			"/?rows=8&bpm=400&rotate=2&channels=5+6+8+1+5+6+8+1&notes=C3+D3+F3+G3+F2+G2+Bb2+C2+Bb3+G2+Bb3+C3+B3+A3+F3+G3+B3+E3+G3+F3+E2+A2+D2+B2+G3+D2+B3+D3+B3+A3+F3+G3",
		label:
			"Teenage Engeneering OPZ: Fast Melody & Rhythm, Fractal Pattern Varied",
	},
	{
		value: "/?rhythm=0&rotate=2&bpm=400",
		label: "Constant Fractal Rhythm, Change of Melody / Instruments Only",
	},
];

const localStorageUrls = localStorage.getItem("presetUrls")
	? JSON.parse(localStorage.getItem("presetUrls"))
	: [];

const App = () => {
	const [currentView, setCurrentView] = useState("all");

	const currentViewRef = useRef(currentView);

	const [leftDeck, setLeftDeck] = useState(left);
	const [rightDeck, setRightDeck] = useState(right);

	const [rowsLength, setRowsLength] = useState(rows);
	const [sequenceIsRhythm, setSequenceIsRhythm] = useState(rhythm);
	const [rotateEvery, setRotateEvery] = useState(rotate);
	const [shouldReplace, setShouldReplace] = useState(replace);

	const [fractalReplace, setFractalReplace] = useState(switcher);

	const [shouldBeVerbose, setShouldBeVerbose] = useState(verbose);

	const [drawerOpen, setDrawerOpen] = useState(false);

	const [bpm, setBpm] = useState(defaultBpm);
	const [beatDistance, setBeatDistance] = useState(bpmToMs(defaultBpm));

	const [refreshRandomKey, setRefreshRandomKey] = useState(0);
	const [randomIndex, setRandomIndex] = useState(0);

	const [refreshFractalKey, setRefreshFractalKey] = useState(0);
	const [fractalIndex, setFractalIndex] = useState(0);

	const [leftPause, setLeftPause] = useState(false);
	const leftPauseRef = useRef(leftPause);

	const [rightPause, setRightPause] = useState(false);
	const rightPauseRef = useRef(rightPause);

	const [timeOutIdsLeft, setTimeOutIdsLeft] = useState([]);
	const timeOutIdsLeftRef = useRef(timeOutIdsLeft);
	const [timeOutIdsRight, setTimeOutIdsRight] = useState([]);
	const timeOutIdsRightRef = useRef(timeOutIdsRight);

	const [reorganizedDataLeft, setReorganizedDataLeft] = useState([]);
	const [reorganizedDataRight, setReorganizedDataRight] = useState([]);

	const [currentCycle, setCurrentCycle] = useState(0);

	const [tempMode, setTempMode] = useState("");

	const [patternTimeSeries, setPatternTimeSeries] = useState({});

	const [webMidi, setWebMidi] = useState();

	const [midiDevices, setMidiDevices] = useState([]);

	const [midiOutput, setMidiOutput] = useState({});

	const [channelsToNotesMap, setChannelsToNotesMap] = useState(channelsToNotes);

	const [savedSettingsUrl, setSavedSettingsUrl] = useState(presetSettingsUrl);

	const [fullSettingsUrl, setFullSettingsUrl] = useState({});

	const [allPresetUrls, setAllPresetUrls] = useState([]);

	const [rotateSolos, setRotateSolos] = useState(!playAllChannelsAtOnce);

	const [currentPositionLeft, setCurrentPositionLeft] = useState(0);
	const [currentPositionRight, setCurrentPositionRight] = useState(0);

	const [presetName, setPresetName] = useState("User Preset: Custom Name");
	const [savedPresetName, setSavedPresetsName] = useState("");

	const updatePresetName = (value) => {
		setPresetName(value);
		setSavedPresetsName(value);
	};

	const updateRotateSolos = (value) => {
		setRotateSolos(value);
	};

	function updateDrawerOpen() {
		setDrawerOpen((prevOpen) => !prevOpen);
	}

	function updateCurrentPositionLeft(value) {
		setCurrentPositionLeft(value);
	}
	function updateCurrentPositionRight(value) {
		setCurrentPositionRight(value);
	}

	function updateTimeOutIdsLeft(newIds) {
		setTimeOutIdsLeft(newIds);
	}
	function updateTimeOutIdsRight(newIds) {
		setTimeOutIdsRight(newIds);
	}

	function updateMidiOutput(value) {
		const newMidiOutput = WebMidi.getOutputById(value);
		setMidiOutput({
			value: newMidiOutput.id,
			label: newMidiOutput.name,
		});
	}

	function updateLeftPause() {
		setLeftPause((prevPause) => !prevPause);
	}

	function updateRightPause() {
		setRightPause((prevPause) => !prevPause);
	}

	function updatePause() {
		updateLeftPause();
		updateRightPause();
		setPause((prevPause) => !prevPause);
	}

	function updateBpm(value) {
		console.log(`changing bpm to ${value}`);
		console.log(`changing beat distance to ${bpmToMs(parseInt(value))}`);
		setBpm(parseInt(value));
	}

	function updateShouldBeVerbose(value) {
		setShouldBeVerbose(value);
	}

	function updateShouldReplace(value) {
		setShouldReplace(parseInt(value));
	}

	function updateFractalReplace(value) {
		setFractalReplace(value);
	}

	function updateRotateEvery(value) {
		setRotateEvery(parseInt(value));
	}

	function updateSequenceRhythm(value) {
		setSequenceIsRhythm(value);
	}

	function updateRowsLength(value) {
		setRowsLength(value);
	}

	function updateLeftDeck(value) {
		setLeftDeck(value);
	}

	function updateRightDeck(value) {
		setRightDeck(value);
	}

	function updateTimeOutIds(newIds) {
		setTimeOutIds(newIds);
	}

	function updateReorganizedDataLeft(array) {
		setReorganizedDataLeft(array);
	}

	function updateReorganizedDataRight(array) {
		setReorganizedDataRight(array);
	}

	function updateCurrentCycle(value) {
		setCurrentCycle(parseInt(value));
	}

	function updateTempMode(value) {
		setTempMode(value);
	}

	function updateSavedSettingsUrl(value) {
		setSavedSettingsUrl(value);
	}

	function loadPresetUrl(value) {
		window.location.href = value;
	}

	function addToPresets(url, name) {
		console.log("new name", name);
		const currentStorage = localStorage.getItem("presetUrls")
			? JSON.parse(localStorage.getItem("presetUrls"))
			: [];

		const newStorage = [...currentStorage, { value: url.href, label: name }];

		const defaultAndPreset = [...presetUrls, ...newStorage];
		localStorage.setItem("presetUrls", JSON.stringify(newStorage));
		setAllPresetUrls(defaultAndPreset);
		alert("Saved your configuration to the list of presets.");
	}

	function updatePresetNotes(patternIndex, channelIndex, noteIndex, noteId) {
		const newChannelsToNotes = channelsToNotesMap.map(
			(_pattern, _patternIndex) => {
				if (_patternIndex === patternIndex) {
					return _pattern.map((_channel, _channelIndex) => {
						if (_channelIndex === channelIndex) {
							console.log(_channel);
							return {
								channel: _channel.channel,
								notes: _channel.notes.map((note, _noteIndex) => {
									if (_noteIndex === noteIndex) {
										return noteId;
									}

									return note;
								}),
							};
						}
						return _channel;
					});
				}
				return _pattern;
			}
		);
		console.log("newChannelsToNotes", newChannelsToNotes);
		setChannelsToNotesMap(newChannelsToNotes);
	}

	function updatePresetChannels(patternIndex, channelIndex, channelId) {
		const newChannelsToNotes = channelsToNotesMap.map(
			(_pattern, _patternIndex) => {
				if (_patternIndex === patternIndex) {
					return _pattern.map((_channel, _channelIndex) => {
						if (_channelIndex === channelIndex) {
							return {
								channel: channelId,
								notes: _channel.notes,
							};
						}
						return _channel;
					});
				}
				return _pattern;
			}
		);

		setChannelsToNotesMap(newChannelsToNotes);
	}

	function startPlaying() {
		const userCurrentView = currentViewRef.current;

		if (userCurrentView == "left" || userCurrentView == "right") {
			if (timeOutIdsLeftRef.current)
				timeOutIdsLeftRef.current.forEach((id) => {
					clearTimeout(id);
				});
			if (timeOutIdsRightRef.current)
				timeOutIdsRightRef.current.forEach((id) => {
					clearTimeout(id);
				});
		}

		switch (userCurrentView) {
			case "left":
				if (rightPauseRef.current) {
					setRightPause((prevPause) => !prevPause);
				}
				if (leftPauseRef.current) {
					setRandomIndex((prevIndex) => prevIndex + 1);
				}
				setLeftPause((prevPause) => !prevPause);
				setCurrentPositionLeft(0);
				// setRandomIndex((prevIndex) => prevIndex + 1);
				break;
			case "right":
				if (leftPauseRef.current) {
					setLeftPause((prevPause) => !prevPause);
				}
				if (rightPauseRef.current) {
					setFractalIndex((prevIndex) => prevIndex + 1);
				}
				setRightPause((prevPause) => !prevPause);
				setCurrentPositionRight(0);
				// setFractalIndex((prevIndex) => prevIndex + 1);
				break;
		}
	}

	useEffect(() => {
		leftPauseRef.current = leftPause;
	}, [leftPause]);

	useEffect(() => {
		rightPauseRef.current = rightPause;
	}, [rightPause]);

	useEffect(() => {
		timeOutIdsLeftRef.current = timeOutIdsLeft;
		timeOutIdsRightRef.current = timeOutIdsRight;
	}, [timeOutIdsLeft, timeOutIdsRight]);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.code === "Space") {
				startPlaying();
			}
		};

		window.addEventListener("keydown", handleKeyPress);

		enableWebMidi(setWebMidi);

		setAllPresetUrls([...presetUrls, ...localStorageUrls]);

		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, []);

	useEffect(() => {
		const currentSettingsUrl = new URL(window.location.href);

		const extractedChannels = channelsToNotesMap.map((pattern) => {
			return pattern.map((channels) => {
				return channels.channel;
			});
		});
		const fullChannelArray = extractedChannels.flat();
		currentSettingsUrl.searchParams.set("channels", fullChannelArray.join("+"));

		const extractedNotes = channelsToNotesMap.map((pattern) => {
			return pattern.map((channels) => {
				return channels.notes;
			});
		});

		const fullNoteArray = extractedNotes.flat().flat();
		currentSettingsUrl.searchParams.set("notes", fullNoteArray.join("+"));

		setFullSettingsUrl(currentSettingsUrl);
		updateSavedSettingsUrl(currentSettingsUrl.href);
	}, [channelsToNotesMap]);

	useEffect(() => {
		const allMidiOutputDevices = WebMidi.outputs.map((output) => {
			return {
				value: output.id,
				label: output.name,
			};
		});

		setMidiDevices(allMidiOutputDevices);

		if (allMidiOutputDevices && allMidiOutputDevices.length > 0) {
			if (presetMidi) {
				const myOutput = WebMidi.getOutputByName(presetMidi)
					? WebMidi.getOutputByName(presetMidi)
					: false;

				const myInput = WebMidi.inputs.filter(
					(input) => input.name.slice(0, 16) == myOutput.name.slice(0, 16)
				)[0];

				if (myOutput) {
					setMidiOutput({
						value: myOutput.id,
						label: myOutput.name,
					});
				} else {
					setMidiOutput(allMidiOutputDevices[0]);
				}

				if (myInput) setMidiInputListener(myInput, shouldBeVerbose);
			} else {
				setMidiOutput(allMidiOutputDevices[0]);
				setMidiInputListener(WebMidi.inputs[0], shouldBeVerbose);
			}
		}
	}, [webMidi]);

	useEffect(() => {
		const signalConfig = {
			left: {
				signalLength: 256,
				minWindow: 4,
				scaleGrow: 0.5,
				signalRange: [
					beatDistance - Math.floor(beatDistance / 2),
					beatDistance + Math.floor(beatDistance / 2),
				], // 0, 500
				signalType: leftDeck,
			},
			right: {
				signalLength: 256,
				minWindow: 4,
				scaleGrow: 0.5,
				signalRange: [
					beatDistance - Math.floor(beatDistance / 2),
					beatDistance + Math.floor(beatDistance / 2),
				], // 0, 500
				signalType: rightDeck,
			},
		};

		patternTimeSeries.left = returnTimeSeries(signalConfig.left).timeSeries;
		patternTimeSeries.right = returnTimeSeries(signalConfig.right).timeSeries;

		setPatternTimeSeries({
			left: patternTimeSeries.left,
			right: patternTimeSeries.right,
		});

		// const averageLeft =
		// 	patternTimeSeries.left.reduce((a, b) => a + b, 0) /
		// 	patternTimeSeries.left.length;
		// const averageRight =
		// 	patternTimeSeries.right.reduce((a, b) => a + b, 0) /
		// 	patternTimeSeries.right.length;
	}, [leftDeck, rightDeck]);

	useEffect(() => {
		if (leftPauseRef.current || currentView == "right") return;

		if (randomIndex >= patternTimeSeries["left"].length) {
			setRandomIndex(0);
		}

		const randomInterval = setInterval(() => {
			setRefreshRandomKey((prevKey) => prevKey + 1);
			setRandomIndex((prevIndex) => {
				return prevIndex < patternTimeSeries["left"].length - 1
					? prevIndex + 1
					: prevIndex;
			});
		}, patternTimeSeries["left"][randomIndex]);

		return () => clearInterval(randomInterval);
	}, [randomIndex]);

	useEffect(() => {
		if (rightPauseRef.current || currentView == "left") return;

		if (fractalIndex >= patternTimeSeries["right"].length) {
			setFractalIndex(0);
		}

		const fractalInterval = setInterval(() => {
			setRefreshFractalKey((prevKey) => prevKey + 1);
			setFractalIndex((prevIndex) => {
				return prevIndex < patternTimeSeries["right"].length - 1
					? prevIndex + 1
					: prevIndex;
			});
		}, patternTimeSeries["right"][fractalIndex]);

		return () => clearInterval(fractalInterval);
	}, [fractalIndex]);

	useEffect(() => {
		currentViewRef.current = currentView;

		if (currentView == "all") {
			if (leftPauseRef.current) {
				setFractalIndex((prevIndex) => prevIndex + 1);
			}
			if (rightPauseRef.current) {
				setRandomIndex((prevIndex) => prevIndex + 1);
			}
		}
		// setRandomIndex((prevIndex) => prevIndex + 1);
		//

		// if (currentView == "all" && leftPause) {
		// 	updateCurrentPositionLeft(currentPositionLeft + 1);
		// }
		// if (currentView == "all" && rightPause) {
		// 	updateCurrentPositionRight(currentPositionRight + 1);
		// }
		// if (timeOutIds) {
		// 	timeOutIds.forEach((id) => {
		// 		clearTimeout(id);
		// 	});
		// }
	}, [currentView]);

	useEffect(() => {
		const currentSettingsUrl = fullSettingsUrl.href
			? fullSettingsUrl
			: new URL(window.location.href);
		currentSettingsUrl.searchParams.set("rows", rowsLength);
		setFullSettingsUrl(currentSettingsUrl);
		updateSavedSettingsUrl(currentSettingsUrl.href);
	}, [rowsLength]);

	useEffect(() => {
		const currentSettingsUrl = fullSettingsUrl.href
			? fullSettingsUrl
			: new URL(window.location.href);
		currentSettingsUrl.searchParams.set("rhythm", sequenceIsRhythm);
		setFullSettingsUrl(currentSettingsUrl);
		updateSavedSettingsUrl(currentSettingsUrl.href);
	}, [sequenceIsRhythm]);

	useEffect(() => {
		const currentSettingsUrl = fullSettingsUrl.href
			? fullSettingsUrl
			: new URL(window.location.href);
		currentSettingsUrl.searchParams.set("rotate", rotateEvery);
		setFullSettingsUrl(currentSettingsUrl);
		updateSavedSettingsUrl(currentSettingsUrl.href);
	}, [rotateEvery]);

	useEffect(() => {
		const currentSettingsUrl = fullSettingsUrl.href
			? fullSettingsUrl
			: new URL(window.location.href);
		currentSettingsUrl.searchParams.set("chorus", !rotateSolos);
		setFullSettingsUrl(currentSettingsUrl);
		updateSavedSettingsUrl(currentSettingsUrl.href);
	}, [rotateSolos]);

	useEffect(() => {
		const currentSettingsUrl = fullSettingsUrl.href
			? fullSettingsUrl
			: new URL(window.location.href);
		currentSettingsUrl.searchParams.set("replace", shouldReplace);
		setFullSettingsUrl(currentSettingsUrl);
		updateSavedSettingsUrl(currentSettingsUrl.href);
	}, [shouldReplace]);

	useEffect(() => {
		const currentSettingsUrl = fullSettingsUrl.href
			? fullSettingsUrl
			: new URL(window.location.href);
		currentSettingsUrl.searchParams.set("switcher", fractalReplace);
		setFullSettingsUrl(currentSettingsUrl);
		updateSavedSettingsUrl(currentSettingsUrl.href);
	}, [fractalReplace]);

	useEffect(() => {
		const currentSettingsUrl = fullSettingsUrl.href
			? fullSettingsUrl
			: new URL(window.location.href);
		currentSettingsUrl.searchParams.set("left", leftDeck);
		setFullSettingsUrl(currentSettingsUrl);
		updateSavedSettingsUrl(currentSettingsUrl.href);
	}, [leftDeck]);

	useEffect(() => {
		const currentSettingsUrl = fullSettingsUrl.href
			? fullSettingsUrl
			: new URL(window.location.href);
		currentSettingsUrl.searchParams.set("right", rightDeck);
		setFullSettingsUrl(currentSettingsUrl);
		updateSavedSettingsUrl(currentSettingsUrl.href);
	}, [rightDeck]);

	useEffect(() => {
		setBeatDistance(bpmToMs(parseInt(bpm)));
		const currentSettingsUrl = fullSettingsUrl.href
			? fullSettingsUrl
			: new URL(window.location.href);
		currentSettingsUrl.searchParams.set("bpm", bpm);
		setFullSettingsUrl(currentSettingsUrl);
		updateSavedSettingsUrl(currentSettingsUrl.href);
	}, [bpm]);

	useEffect(() => {
		const currentSettingsUrl = fullSettingsUrl.href
			? fullSettingsUrl
			: new URL(window.location.href);
		currentSettingsUrl.searchParams.set("verbose", shouldBeVerbose);
		setFullSettingsUrl(currentSettingsUrl);
		updateSavedSettingsUrl(currentSettingsUrl.href);
	}, [shouldBeVerbose]);

	useEffect(() => {
		const currentSettingsUrl = fullSettingsUrl.href
			? fullSettingsUrl
			: new URL(window.location.href);
		currentSettingsUrl.searchParams.set("midi", midiOutput.label);
		setFullSettingsUrl(currentSettingsUrl);
		updateSavedSettingsUrl(currentSettingsUrl.href);
	}, [midiOutput]);

	const toggleLeftView = () => {
		setCurrentView(currentView === "left" ? "all" : "left");
	};

	const toggleRightView = () => {
		setCurrentView(currentView === "right" ? "all" : "right");
	};

	const containerClasses =
		currentView === "all"
			? "flex flex-col lg:flex-row gap-32 w-4/5 h-4/5 items-center justify-center"
			: "flex flex-col lg:flex-row gap-16 lg:w-2/5 w-4/5 items-center justify-center";

	return (
		<div class="container mx-auto w-screen h-screen flex items-center justify-center bg-black">
			<div class={containerClasses}>
				{currentView != "right" && (
					<Grid
						key={refreshRandomKey}
						mode={leftDeck}
						onTouchStart={toggleLeftView}
						onClick={toggleLeftView}
						rows={rowsLength}
						verbose={shouldBeVerbose}
						rotate={rotateEvery}
						rotateSolos={rotateSolos}
						replace={shouldReplace}
						fractalReplace={fractalReplace}
						tempMode={tempMode}
						updateTempMode={updateTempMode}
						WebMidi={WebMidi}
						pause={leftPause}
						timeOutIds={timeOutIdsLeft}
						updateTimeOutIds={updateTimeOutIdsLeft}
						reorganizedData={reorganizedDataLeft}
						updateReorganizedData={updateReorganizedDataLeft}
						formToRhythm={sequenceIsRhythm}
						currentCycle={currentCycle}
						updateCurrentCycle={updateCurrentCycle}
						beatDistance={beatDistance}
						midiOutput={midiOutput}
						channelsToNotes={channelsToNotesMap}
						currentPosition={currentPositionLeft}
						updateCurrentPosition={updateCurrentPositionLeft}
						currentView={currentView}
					/>
				)}
				{currentView != "left" && (
					<Grid
						key={refreshFractalKey}
						mode={rightDeck}
						onTouchStart={toggleRightView}
						onClick={toggleRightView}
						rows={rowsLength}
						verbose={shouldBeVerbose}
						rotate={rotateEvery}
						rotateSolos={rotateSolos}
						tempMode={tempMode}
						updateTempMode={updateTempMode}
						replace={shouldReplace}
						fractalReplace={fractalReplace}
						WebMidi={WebMidi}
						pause={rightPause}
						timeOutIds={timeOutIdsRight}
						updateTimeOutIds={updateTimeOutIdsRight}
						reorganizedData={reorganizedDataRight}
						updateReorganizedData={updateReorganizedDataRight}
						formToRhythm={sequenceIsRhythm}
						currentCycle={currentCycle}
						updateCurrentCycle={updateCurrentCycle}
						beatDistance={beatDistance}
						midiOutput={midiOutput}
						channelsToNotes={channelsToNotesMap}
						currentPosition={currentPositionRight}
						updateCurrentPosition={updateCurrentPositionRight}
						currentView={currentView}
					/>
				)}
			</div>
			<Settings
				updateDrawerOpen={updateDrawerOpen}
				SequenceTypes={SequenceTypes}
				leftDeck={leftDeck}
				leftPause={leftPause}
				updateLeftDeck={updateLeftDeck}
				rightDeck={rightDeck}
				rightPause={rightPause}
				updateRightDeck={updateRightDeck}
				midiOutput={midiOutput}
				updateMidiOutput={updateMidiOutput}
				midiDevices={midiDevices}
				rowsLength={rowsLength}
				updateRowsLength={updateRowsLength}
				SequenceLengths={SequenceLengths}
				sequenceIsRhythm={sequenceIsRhythm}
				updateSequenceRhythm={updateSequenceRhythm}
				SequenceIsRhythm={SequenceIsRhythm}
				rotateEvery={rotateEvery}
				updateRotateEvery={updateRotateEvery}
				SequenceRotateEvery={SequenceRotateEvery}
				shouldReplace={shouldReplace}
				updateShouldReplace={updateShouldReplace}
				SequenceReplace={SequenceReplace}
				fractalReplace={fractalReplace}
				updateFractalReplace={updateFractalReplace}
				SequenceFractalReplace={SequenceFractalReplace}
				rotateSolos={rotateSolos}
				updateRotateSolos={updateRotateSolos}
				SequenceRotateSolos={SequenceRotateSolos}
				bpm={bpm}
				updateBpm={updateBpm}
				channelsToNotesMap={channelsToNotesMap}
				updatePresetChannels={updatePresetChannels}
				updatePresetNotes={updatePresetNotes}
				savedSettingsUrl={savedSettingsUrl}
				fullSettingsUrl={fullSettingsUrl}
				presetSettingsUrl={presetSettingsUrl}
				loadPresetUrl={loadPresetUrl}
				allPresetUrls={allPresetUrls}
				shouldBeVerbose={shouldBeVerbose}
				updateShouldBeVerbose={updateShouldBeVerbose}
				SequenceVerbose={SequenceVerbose}
				addToPresets={addToPresets}
				presetName={presetName}
				updatePresetName={updatePresetName}
				savedPresetName={savedPresetName}
				channelsInChords={channelsInChords}
			/>
			<Helper></Helper>
			{currentView == "left" && (
				<Button
					customClasses="fixed bottom-5 left-1/2 text-xl transform -translate-x-1/2 bg-black text-clinical-600 mr-2 py-2 px-4"
					onClick={startPlaying}
					label={leftPause ? "\u007C \u007C" : "\u25B6"}
				></Button>
			)}
			{currentView == "right" && (
				<Button
					customClasses="fixed bottom-5 left-1/2 text-xl transform -translate-x-1/2 bg-black text-clinical-600 mr-2 py-2 px-4"
					onClick={startPlaying}
					label={rightPause ? "\u007C \u007C" : "\u25B6"}
				></Button>
			)}
		</div>
	);
};

function detectSafari() {
	return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

render(<App />, document.getElementById("app"));
